import * as React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// import { renderRichText } from "gatsby-source-contentful/rich-text";
// import { BLOCKS, MARKS } from "@contentful/rich-text-types";

//components
import Layout from '../components/layout';
import Title from '../components/title';
import SEO from '../components/seo';

//styles
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import * as styles from '../styles/pages/itinerary.module.scss';

export default function Itinerary() {
  const data = useStaticQuery(graphql`
    query {
      itinerary1: file(relativePath: { eq: "forts_frescos.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      itinerary2: file(relativePath: { eq: "flora_and_fauna.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      itinerary3: file(relativePath: { eq: "island_spin.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      itinerary4: file(relativePath: { eq: "shanthi_and_prana.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      itinerary5: file(relativePath: { eq: "zephyr_and_waves.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const itineraries = [
    {
      slug: 'forts-and-frescos',
      title: 'Forts & Frescos',
      duration: '11 Nights/ 12 Days',
      img: data.itinerary1.childImageSharp.gatsbyImageData,
    },
    {
      slug: 'flora-and-fauna',
      title: 'Flora & Fauna',
      duration: '10 Nights / 11 Days',
      img: data.itinerary2.childImageSharp.gatsbyImageData,
    },
    {
      slug: 'island-spin',
      title: 'Island Spin',
      duration: '06 Nights / 07 Days',
      img: data.itinerary3.childImageSharp.gatsbyImageData,
    },
    {
      slug: 'shanthi-and-prana',
      title: 'Shanthi & Prana',
      duration: '08 Nights / 09 Days',
      img: data.itinerary4.childImageSharp.gatsbyImageData,
    },
    {
      slug: 'zephyr-and-waves',
      title: 'Zephyr & Waves',
      duration: '08 Nights / 09 Days',
      img: data.itinerary5.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <>
      <Layout>
        <Title picture title='Itineraries' />
        <SEO title='Itineraries' />
        <Container className='mt-5 pt-5'>
          <Row className='g-5'>
            {itineraries.map((itinerary) => {
              return (
                <Col md={4}>
                  <Card
                    className={`${styles.card} p-0`}
                    onClick={() => navigate(`/itinerary/${itinerary.slug}`)}
                  >
                    <GatsbyImage image={itinerary.img} alt={itinerary.title} />

                    <Card.Body className='p-4'>
                      <Card.Title className='fw-bold'>
                        {itinerary.title}
                      </Card.Title>
                      <p>{itinerary.duration}</p>
                      <hr />
                      <Button variant='secondary-rounded'>Read More</Button>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Layout>
    </>
  );
}
